<template>
		<div>
			<TheNavBar v-if="!showNav" />
			<SpinnersGlobal />
			<KeyboardShortcutSidebar
			@openCustomization="openCustomization"
				:open="shortcutOpen"
				@close="shortcutClose" />
			<GlobalSearch v-if="store.$state.showSearch" />
			<VideoPlayer v-if="store.$state.showVideoPlayer" />
			<KeyboardShortcutCustomize @closeCustomization="closeCustomization" v-if="openCustomizationModal"/>
			<slot></slot>
		</div>
</template>

<style>
@import "vue-draggable-resizable/style.css";
</style>

<script setup>
import { Routes, AddOns } from "~/constants";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const store = useGlobalStore();
const settingStore = useSettingStore();

const showNav = computed(() => store.getNavStatus);
const route = useRoute();
const router = useRouter();
const shortcutOpen = ref(false);
const addOnStore = useAddOnStore();
const openCustomizationModal = ref(false); 

const { isMacOS, isWindows } = useDevice();


const openCustomization = () => {
	openCustomizationModal.value = true
}

const closeCustomization = () => {
	openCustomizationModal.value = false
}

const isShortcutKeyEnabled = computed(() => {
	return addOnStore.isAddOnEnabled({ entity: AddOns.SHORTCUTS });
});

const ShortcutMap = [
	{ keys: ["ctrl", "t"], route: Routes.Tickets.New },
	{ keys: ["meta", "t"], route: Routes.Tickets.New },
	{ keys: ["ctrl", "c"], route: Routes.Campaign.Newsletter.New.Sender },
	{ keys: ["meta", "c"], route: Routes.Campaign.Newsletter.New.Sender },
	{ keys: ["ctrl", "a"], route: Routes.Campaign.ABTest.New.Sender },
	{ keys: ["meta", "a"], route: Routes.Campaign.ABTest.New.Sender },
	{
		keys: ["ctrl", "shift", "a"],
		route: Routes.Campaign.AutoTrigger.New.Sender,
	},
	{
		keys: ["meta", "shift", "a"],
		route: Routes.Campaign.AutoTrigger.New.Sender,
	},
	{ keys: ["ctrl", "d"], route: Routes.Campaign.Drip.New.Sender },
	{ keys: ["meta", "d"], route: Routes.Campaign.Drip.New.Sender },
	{ keys: ["ctrl", "r"], route: Routes.Campaign.RSS.New.Basic },
	{ keys: ["meta", "r"], route: Routes.Campaign.RSS.New.Basic },
	{ keys: ["ctrl", "u"], route: Routes.Automate.Workflow.New.Edit },
	{ keys: ["meta", "u"], route: Routes.Automate.Workflow.New.Edit },
	{ keys: ["ctrl", "i"], route: Routes.Audience.List.Home },
	{ keys: ["meta", "i"], route: Routes.Audience.List.Home },
	{ keys: ["ctrl", "g"], route: Routes.Audience.Tag.Home },
	{ keys: ["meta", "g"], route: Routes.Audience.Tag.Home },
	{ keys: ["ctrl", "shift", "e"], route: Routes.Audience.Segment.Home },
	{ keys: ["meta", "shift", "e"], route: Routes.Audience.Segment.Home },
	{ keys: ["ctrl", "o"], route: Routes.Audience.Contact.Home },
	{ keys: ["meta", "o"], route: Routes.Audience.Contact.Home },
	{ keys: ["ctrl", "f"], route: Routes.Form.New.Type },
	{ keys: ["meta", "f"], route: Routes.Form.New.Type },
	{ keys: ["ctrl", "m"], route: Routes.Automate.Automation.New.Name },
	{ keys: ["meta", "m"], route: Routes.Automate.Automation.New.Name },
	{ keys: ["ctrl", "shift", "i"], route: Routes.Setting.Setup.DomainWhitelisting },
	{ keys: ["meta", "shift", "i"], route: Routes.Setting.Setup.DomainWhitelisting },
	{ keys: ["ctrl", "h"], route: Routes.Home },
	{ keys: ["meta", "h"], route: Routes.Home },
	{ keys: ["ctrl", "shift", "d"], route: Routes.Dashboard },
	{ keys: ["meta", "shift", "d"], route: Routes.Dashboard },
];

const handleShortcutOpen = () => {
	shortcutOpen.value = !shortcutOpen.value;
};

const shortcutClose = () => {
	shortcutOpen.value = false;
};

const navigateToRoute = async (route) => {
	await navigateTo(route);
};

const handleBack = () => {
	router.back();
};

const bus = useEventBus("resetData");

const memTeams = computed(() => {
	const data = store.getMemberTeams;
	if (data) {
		return data;
	}
	return false;
});

const currentTeamId = computed(() => {
	return store.$state.currentTeam.id;
});

const resetData = async () => {
	store.globalSpinnerStart();
	await Promise.all([
		settingStore.getBillingState(),
		store.fetchAllMemberTeams()
	]);
	store.globalSpinnerStop();
};

const switchTeamShortcut = async () => {
	if (!memTeams.value || memTeams.value.length === 0) {
		toast("No teams available to switch", {
			theme: "auto",
			type: "warning",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
		return;
	}

	const currentIndex = memTeams.value.findIndex(
		(sm) => sm.team.id === currentTeamId.value
	);

	const nextIndex = (currentIndex + 1) % memTeams.value.length;
	const nextTeam = memTeams.value[nextIndex].team;

	if (!nextTeam || nextTeam.id === currentTeamId.value) {
		toast("No team to switch to", {
			theme: "auto",
			type: "warning",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
		return;
	}

	store.globalSpinnerStart();
	try {
		await store.switchTeam({ sid: nextTeam.id });

		if (route.name === "dashboard") {
			bus.emit();
		} else {
			await navigateTo(Routes.Dashboard);
		}

		await resetData();

		toast(`Successfully switched to team ${nextTeam.name}`, {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
			position: "bottom-right",
		});
	} catch (error) {
		console.error("An error occurred while switching team:", error);
		toast("An error occurred while switching team", {
			theme: "auto",
			type: "warning",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
	} finally {
		store.globalSpinnerStop();
	}

	shortcutClose()
};

function initializeHubSpot() {
	const script = document.createElement("script");
	script.type = "text/javascript";
	script.id = "hs-script-loader";
	script.src = "https://js.hs-scripts.com/20294495.js";
	window.document.body.appendChild(script);

	function onConversationsAPIReady() {
		console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);
		window.HubSpotConversations.widget.load();
	}

	window.hsConversationsSettings = {
		loadImmediately: false,
		identificationEmail: store.$state.authInfo.account.Email,
		identificationToken: store.$state.authInfo.hubspotToken,
	};

	if (window.HubSpotConversations) {
		onConversationsAPIReady();
	} else {
		window.hsConversationsOnReady = [onConversationsAPIReady];
	}
}

function toggleHubSpotVisibility(show) {
	if (window.HubSpotConversations && window.HubSpotConversations.widget) {
		if (show) {
			window.HubSpotConversations.widget.load(); // re load widget
		} else {
			window.HubSpotConversations.widget.remove(); // Hide widget
		}
	}
}

onBeforeMount(() => {
	store.setResource({ resource: "showSearch", value: false });

	if (!route.path.includes("/content")) {
		initializeHubSpot();
	}

	// window.beamer_config = {
	// 	product_id: "blffVaxz12558",
	// 	button_position: "bottom-right",
	// 	user_email: store.$state.authInfo?.member?.email || "",
	// };
	// const beamerScript = document.createElement("script");
	// beamerScript.type = "text/javascript";
	// beamerScript.src = "https://app.getbeamer.com/js/beamer-embed.js";
	// beamerScript.defer = true;
	// window.document.body.appendChild(beamerScript);
});

watch(
	() => route.path,
	(newPath) => {
		const shouldShowHubSpot = !newPath.includes("/content");
		toggleHubSpotVisibility(shouldShowHubSpot);
	}
);

const pressedKeys = [];
let timeout = null;

const showSearch = () => {
	store.setResource({
		resource: "showSearch",
		value: !store.$state.showSearch,
	});
	store.setResource({ resource: "searchTabSelected", value: 2 });
};

const showVideoSearch = () => {
	store.setResource({
		resource: "showSearch",
		value: !store.$state.showSearch,
	});
	store.setResource({ resource: "searchTabSelected", value: 1 });
};


const shortcutActionsMap = {
  "Open Shortcut Menu": () => handleShortcutOpen(),
  "Go Back": () => handleBack(),
  "Open Search": () => showSearch(),
  "Open Video Search": () => showVideoSearch(),

  "New Newsletter Campaign": () => navigateTo(Routes.Campaign.Newsletter.New.Sender),
  "New A/B Test Campaign": () => navigateTo(Routes.Campaign.ABTest.New.Sender),
  "New Auto Trigger Campaign": () => navigateTo(Routes.Campaign.AutoTrigger.New.Sender),
  "New Ticket": () => navigateTo(Routes.Tickets.New),
  "New Drip Campaign": () => navigateTo(Routes.Campaign.Drip.New.Sender),
  "New RSS Campaign": () => navigateTo(Routes.Campaign.RSS.New.Basic),
  "New Workflow": () => navigateTo(Routes.Automate.Workflow.New.Edit),
  "New Form": () => navigateTo(Routes.Form.New.Type),
  "New Automation": () => navigateTo(Routes.Automate.Automation.New.Name),

  "Go to Newsletter Campaigns": () => navigateTo(Routes.Campaign.Newsletter.Home),
  "Go to Workflows": () => navigateTo(Routes.Automate.Workflow.Home),
  "Go to Automations": () => navigateTo(Routes.Automate.Automation.Home),
  "Go to A/B Test Campaigns": () => navigateTo(Routes.Campaign.ABTest.Home),
  "Go to Auto Trigger Campaigns": () => navigateTo(Routes.Campaign.AutoTrigger.Home),
  "Go to Drip Campaigns": () => navigateTo(Routes.Campaign.Drip.Home),
  "Go to RSS Campaigns": () => navigateTo(Routes.Campaign.RSS.Home),
  "Go to Audience Lists": () => navigateTo(Routes.Audience.List.Home),
  "Go to Audience Tags": () => navigateTo(Routes.Audience.Tag.Home),
  "Go to Form Popup": () => navigateTo(Routes.Form.Popup),
  "Go to Settings": () => navigateTo(Routes.Setting.Setup.DomainWhitelisting),
  "Go to Audience Segments": () => navigateTo(Routes.Audience.Segment.Home),
  "Go to Contacts": () => navigateTo(Routes.Audience.Contact.Home),
  "Go to Tickets": () => navigateTo(Routes.Tickets.Home),
  "Go to Dashboard": () => navigateTo(Routes.Dashboard),

  "Switch Team": () => switchTeamShortcut(),
};

const shortcuts = ref(JSON.parse(store.$state.shortCutkeys))

const resetKeys = () => {
	pressedKeys.length = 0;
	if (timeout) {
		clearTimeout(timeout);
		timeout = null;
	}
};

watch(() => store.$state.shortCutkeys, () => {
	shortcuts.value = JSON.parse(store.$state.shortCutkeys);
})

const handleKeyDown = (event) => {
	// Prevent shortcuts inside input fields and textareas

	const activeElement = document.activeElement

	console.log(activeElement.className.includes('shortcut-input'), "activeElement");

	if (
		event.target.tagName === "INPUT" ||
		event.target.tagName === "TEXTAREA" ||
		event.target.isContentEditable || activeElement.className.includes('shortcut-input')
	) {
		return;
	}

	console.log(event, "1 in handleKeyDown");

	if(event.key === 'Control' && isWindows){
		pressedKeys.push("Meta");
	}
	else {
		pressedKeys.push(event.key);
	}
	 // Add key to the sequence

	if (timeout) {
		clearTimeout(timeout);
		console.log("2 clear timeout handleKeyDown");
	}

	timeout = setTimeout(resetKeys, 1000); // Reset after 1 second

	for (const shortcut of shortcuts.value) {
		if (
			shortcut.keys.length === pressedKeys.length &&
			shortcut.keys.every((key, index) => key === pressedKeys[index])
		) {
			event.preventDefault();
			const shortcutTitle = shortcut.title; // Assuming shortcut object has a title
			const shortcutUsed = pressedKeys.join(" + ");
			
			store.sendSlackNotificationShortcutKety({email: store.$state.authInfo.account.Email, shortcutTitle: shortcutTitle, shortcutUsed: shortcutUsed})

			resetKeys();
			console.log(shortcutActionsMap[shortcut.title], "action");
			shortcutActionsMap[shortcut.title]();
			break;
		}
	}
};

const getShortcutKeys = async () => {
	try {
		const data = await store.getShortcutKeys()
		console.log(data, "shortcut keys");
	} catch (error) {
		console.log(error);
	}
}

onMounted(() => {
	console.log("shortcut mounted");
	if(isShortcutKeyEnabled.value){
		try {
			getShortcutKeys()
		} catch (error) {
			console.log(error);
		}
		finally{
			window.addEventListener("keydown", handleKeyDown);
		}
	}
});
 

watch(() => isShortcutKeyEnabled.value, (curr , pre) => {
	console.log(curr, pre);
	if(curr ){
		window.addEventListener("keydown", handleKeyDown);
	}
	else {
		window.removeEventListener("keydown", handleKeyDown);
	}
})

onUnmounted(() => {
	if(isShortcutKeyEnabled.value){
		window.removeEventListener("keydown", handleKeyDown);
	}
});

</script>

<style>
svg[data-lastpass-icon="true"] {
	display: none !important;
}

div[data-lastpass-icon-root] {
	display: none;
}

html {
	@apply bg-gray-100;
}

/* Page-transition styles must be global */
/* page is used by default */
.page-leave-active,
.page-enter-active {
	transition: opacity 0.3s;
}
.page-enter,
.page-leave-to {
	opacity: 0;
}
/* slide-right */
.slide-right-leave-active,
.slide-right-enter-active {
	transition: all 0.3s;
}
.slide-right-enter {
	opacity: 0;
	transform: translate(-30px, 0);
}
.slide-right-enter-to,
.slide-right-leave {
	opacity: 1;
	transform: translate(0, 0);
}
.slide-right-leave-to {
	opacity: 0;
	transform: translate(30px, 0);
}
/* slide-left */
.slide-left-leave-active,
.slide-left-enter-active {
	transition: all 0.3s;
}
.slide-left-enter {
	opacity: 0;
	transform: translate(30px, 0);
}
.slide-left-enter-to,
.slide-left-leave {
	opacity: 1;
	transform: translate(0, 0);
}
.slide-left-leave-to {
	opacity: 0;
	transform: translate(-30px, 0);
}
/* swipe-right */
.swipe-right-leave-active,
.swipe-right-enter-active {
	transition: all 0.3s;
}
.swipe-right-enter {
	opacity: 0;
	transform: translate(-100%, 0);
}
.swipe-right-enter-to,
.swipe-right-leave {
	opacity: 1;
	transform: translate(0, 0);
}
.swipe-right-leave-to {
	opacity: 0;
	transform: translate(100%, 0);
}
/* jump */
.jump-leave-active,
.jump-enter-active {
	transition: all 0.3s;
}
.jump-enter {
	opacity: 0;
	transform: rotate(-12deg);
}
.jump-enter-to,
.jump-leave {
	opacity: 1;
	transform: rotate(0deg);
}
.jump-leave-to {
	opacity: 0;
	transform: rotate(12deg);
}

::-moz-selection {
	@apply bg-blue-600 text-white;
}
::selection {
	@apply bg-blue-600 text-white;
}
</style>
