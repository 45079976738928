<template>
	<div>
		<transition v-click-outside="closeDropDown"
			enter-active-class="transition ease-out duration-100"
			enter-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95">
			<div
				v-if="profileDropDownOpen"
				class="z-20 origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="user-menu-button"
				tabindex="-1">
				<div class="w-full flex flex-col divide-y divide-y-gray-50">
					
					<div class="flex flex-col">
						<div class="flex px-4 py-[8px] flex-row space-x-4 items-center justify-start">
							<div class="h-10 w-10">
								<span class="inline-block relative">
									<div class="h-10 w-10 flex flex-col justify-center items-center p-2 bg-blue-500 rounded-full">
										<h1 class="text-white text-sm">{{ getInitials(currentTeam?.name) }}</h1>
									</div>
									<!-- <span
										class="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span> -->
								</span>
							</div>
							<div class="flex flex-col h-full justify-center gap-1">
								<div class="text-gray-800 text-sm leading-[14px] font-medium">
									{{ currentTeam?.name }}
								</div>
								<div v-if="isAccountPaid  && store.checkVisibility({ entity: AppEntities.Billing })" class="text-gray-400 text-sm leading-[14px]">
									<h1 class="text-xs">Up to {{
										billingDetails?.billing?.LastContacts
									}} contacts</h1>
								</div>
							</div>
						</div>
						<nuxt-link
						@click="closeDropDown"
							:to="$config.public.routes.Setting.Setup.DomainWhitelisting"
							class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
							role="menuitem"
							tabindex="-2">
							<div class="flex flex-row items-center space-x-4">
								<span class="text-sm leading-5 font-normal">Settings</span>
							</div>
						</nuxt-link>

						<nuxt-link
							v-if="
								isAccountPaid &&
								store.checkVisibility({ entity: AppEntities.Billing })
							"
							@click="closeDropDown"
							:to="$config.public.routes.Setting.Billing"
							class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
							role="menuitem"
							tabindex="-1">
							<div class="flex flex-row items-center space-x-4">
								<span class="text-sm leading-5 font-normal">
									Billing
								</span>
							</div>
						</nuxt-link>

						<div class="flex flex-col">
							<div @click.stop="switchTeamClicked" class="cursor-pointer text-sm px-4 py-2 text-gray-700 hover:bg-gray-100 flex flex-row justify-between items-center" role="menuitem" tabindex="-1">
								<div class="flex flex-row items-center space-x-4">
									<span class="text-sm leading-5 font-normal">
										Switch Team
									</span>
								</div>
								<div>
									<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1.60742 1.15234L6.07617 5.90234C6.20117 6.05859 6.29492 6.24609 6.29492 6.40234C6.29492 6.58984 6.20117 6.77734 6.07617 6.93359L1.60742 11.6836C1.32617 11.9961 0.857422 11.9961 0.544922 11.7148C0.232422 11.4336 0.232422 10.9648 0.513672 10.6523L4.51367 6.40234L0.513672 2.18359C0.232422 1.87109 0.232422 1.40234 0.544922 1.12109C0.857422 0.839844 1.32617 0.839844 1.60742 1.15234Z" fill="#6B7280"/>
									</svg>
								</div>
							</div>

							<transition v-click-outside="closeDropDown"
								enter-active-class="transition ease-out duration-100"
								enter-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95">
								<div
									v-if="switchDropDownOpen"
									class="z-20 absolute -ml-[198px]

									 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="user-menu-button"
									tabindex="-1">
									<div class="h-[40vh] overflow-y-auto">
										<div v-for="(sa, ind) in memTeams">
											<div
												@click.stop="switchTeam(sa.team.id, sa.team.name)"
												class="flex flex-col divide-y divide-y-gray-50 cursor-pointer">
												<div class="relative group">
													<div
														class="flex justify-between"
														:class="[
															sa.team.id === currentTeam?.id
																? 'bg-green-200'
																: 'hover:bg-gray-100',
														]">
														<div
															class="px-4 py-2 flex space-x-4 items-center">
															<div
																class="flex flex-col justify-between">
																<div
																	class="text-gray-800 leading-5"
																	:class="[
																		sa.team.id === currentTeam?.id
																			? 'font-semibold text-md'
																			: 'text-sm font-medium',
																	]">
																	{{ sa.team.name }}
																</div>
																<div
																	class="text-gray-400 leading-5 text-xs"
																	:class="[
																		sa.team.id === currentTeam?.id
																			? 'font-semibold'
																			: 'font-medium',
																	]">
																	{{
																		sa.team.companyName !== ""
																			? sa.team.companyName
																			: "-"
																	}}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="mb-10"></div>

										<div @click.stop="manageTeamsClicked" class="border-t py-3 px-3 text-sm text-blue-400 absolute bottom-0 bg-white w-full rounded-b-md cursor-pointer">
											Manage teams
										</div>
									</div>
								</div>
							</transition>
						</div>
					</div>

					<div class="flex flex-col">
						<a
							target="_blank"
							rel="noreferrer"
							href="https://sendworks.com/support/sendx"
							class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
							role="menuitem"
							tabindex="-1">
							<div class="flex flex-row items-center justify-between space-x-4">
								<span class="text-sm leading-5 font-normal"
									>Knowledge Base</span
								>
								<span>
									<svg
										width="11"
										height="11"
										viewBox="0 0 11 11"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M10.0762 0.402344C10.4824 0.402344 10.8262 0.746094 10.8262 1.15234V8.65234C10.8262 9.08984 10.4824 9.40234 10.0762 9.40234C9.63867 9.40234 9.32617 9.08984 9.32617 8.65234V2.96484L2.10742 10.1836C1.79492 10.4961 1.32617 10.4961 1.04492 10.1836C0.732422 9.90234 0.732422 9.43359 1.04492 9.15234L8.26367 1.93359L2.57617 1.90234C2.13867 1.90234 1.82617 1.58984 1.82617 1.15234C1.82617 0.746094 2.13867 0.402344 2.57617 0.402344H10.0762Z"
											fill="#6B7280" />
									</svg>
								</span>
							</div>
						</a>

						<nuxt-link
						@click="closeDropDown"
							:to="$config.public.routes.Tickets.Home"
							class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
							role="menuitem"
							tabindex="-2">
							<div class="flex flex-row items-center justify-between space-x-4">
								<span class="text-sm leading-5 font-normal"
									>Create Ticket</span
								>
								<span>
									<svg
										width="11"
										height="11"
										viewBox="0 0 11 11"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M10.0762 0.402344C10.4824 0.402344 10.8262 0.746094 10.8262 1.15234V8.65234C10.8262 9.08984 10.4824 9.40234 10.0762 9.40234C9.63867 9.40234 9.32617 9.08984 9.32617 8.65234V2.96484L2.10742 10.1836C1.79492 10.4961 1.32617 10.4961 1.04492 10.1836C0.732422 9.90234 0.732422 9.43359 1.04492 9.15234L8.26367 1.93359L2.57617 1.90234C2.13867 1.90234 1.82617 1.58984 1.82617 1.15234C1.82617 0.746094 2.13867 0.402344 2.57617 0.402344H10.0762Z"
											fill="#6B7280" />
									</svg>
								</span>
							</div>
						</nuxt-link>

						<div
							@click="openBeamerIdeas"
							class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100">
							<div class="flex flex-row items-center space-x-4">
								<span class="text-sm leading-5 font-normal"
									>Feature Request</span
								>
							</div>
						</div>

						<div
							@click="openBeamerRoadmap"
							class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100">
							<div class="flex flex-row items-center space-x-4">
								<span class="text-sm leading-5 font-normal"
									>Product Roadmap</span
								>
							</div>
						</div>

						<!-- <button @click="enableDarkReader" class="text-sm px-3 py-2 bg-gray-200 dark:bg-gray-800 rounded hover:bg-gray-300 dark:hover:bg-gray-700 transition">
							Toggle Dark Reader
						</button> -->

						<a
							target="_blank"
							rel="noreferrer"
							href="https://docs.sendx.io/"
							class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
							role="menuitem"
							tabindex="-1">
							<div
								class="flex flex-row items-center w-full justify-between space-x-4">
								<span class="text-sm leading-5 font-normal">API</span>
								<span>
									<svg
										width="11"
										height="11"
										viewBox="0 0 11 11"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M10.0762 0.402344C10.4824 0.402344 10.8262 0.746094 10.8262 1.15234V8.65234C10.8262 9.08984 10.4824 9.40234 10.0762 9.40234C9.63867 9.40234 9.32617 9.08984 9.32617 8.65234V2.96484L2.10742 10.1836C1.79492 10.4961 1.32617 10.4961 1.04492 10.1836C0.732422 9.90234 0.732422 9.43359 1.04492 9.15234L8.26367 1.93359L2.57617 1.90234C2.13867 1.90234 1.82617 1.58984 1.82617 1.15234C1.82617 0.746094 2.13867 0.402344 2.57617 0.402344H10.0762Z"
											fill="#6B7280" />
									</svg>
								</span>
							</div>
						</a>
					</div>

					<div class="w-full flex flex-col">
						<div class="w-full flex px-4 py-[8px] flex-row space-x-4 items-center">
							<div class="w-10 h-10">
								<span class="inline-block relative">
									<img
										class="h-10 w-10 rounded-full"
										:src="
											store.$state.memberAvatarUrl != ''
												? store.$state.memberAvatarUrl
												: tempAvatarUrl
										"
										alt="" />
									<span
										class="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
								</span>
							</div>
							<div class="flex flex-col justify-between w-[75%]">
								<div class="text-gray-800 text-xs leading-5 font-medium truncate">
									{{ store.$state.authInfo?.account.AccountName }}
								</div>
							</div>
						</div>
						<nuxt-link
						@click="closeDropDown"
							:to="$config.public.routes.Profile"
							class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
							role="menuitem"
							tabindex="-1"
							id="user-menu-item-0">
							<div class="flex flex-row items-center space-x-4">
								<span class="text-sm leading-5 font-normal">Profile</span>
							</div>
						</nuxt-link>
						<div
							@click.stop="signOut"
							class="cursor-pointer text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
							role="menuitem"
							tabindex="-1">
							<div class="flex flex-row items-center space-x-4">
								<span class="text-sm leading-5 font-normal">Sign out</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<DeleteModalForm
			:active="isDeleteComponentModalActive"
			:entityName="entityName"
			:elementId="elementId"
			:elementName="elementName"
			:deleteLoader="teamLoader"
			@cancelModalClicked="cancelDeleteModalClicked"
			@deleteModalClicked="(value) => deleteTeam(value)" 
		/>

		<div v-if="manageTeamsSidebar">
			<SettingTeamSidebar 
				:open="manageTeamsSidebar"
				@closeTeamSidebar="closeTeamSidebar" 
			/>
		</div>
	</div>
</template>

<script setup>
import { AppEntities, Routes } from "~/constants";
import { useEventBus } from "@vueuse/core";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const store = useGlobalStore();
const sStore = useSettingStore();
const billingDetails = ref({});
const route = useRoute();
const bus = useEventBus("resetData");
const switchDropDownOpen = ref(false);
const teamMenuOpenId = ref(-1);
const teamMenuOpen = ref(false);
const entityName = ref("Team");
const elementId = ref(0);
const elementName = ref("");
const isDeleteComponentModalActive = ref(false);
const teamLoader = ref(false);
const manageTeamsSidebar = ref(false)

const props = defineProps({
	profileDropDownOpen: {
		type: Boolean,
		default: false,
	},
	isAccountPaid: {
		type: Boolean,
	}
});

const manageTeamsClicked = () => {
	manageTeamsSidebar.value = true
}

const closeTeamSidebar = () => {
	manageTeamsSidebar.value = false
}

const teamMenuCliked = (ind) => {
	if (teamMenuOpen.value === false) {
		teamMenuOpenId.value = ind;
		teamMenuOpen.value = true;
	} else {
		if (teamMenuOpenId.value !== -1 && teamMenuOpenId.value !== ind) {
			teamMenuOpenId.value = ind;
			teamMenuOpen.value = true;
		} else {
			teamMenuOpenId.value = -1;
			teamMenuOpen.value = false;
		}
	}
};

const getShortcutKeys = async () => {
	try {
		const data = await store.getShortcutKeys()
		console.log(data, "shortcut keys");
	} catch (error) {
		console.log(error);
	}
}

const closeSwitchDropDown = () => {
	switchDropDownOpen.value = false;
};

const currentTeam = computed(() => {
	return store.currentTeam
})

const switchTeam = async (id, name) => {
	if (id === currentTeam.value?.id) {
		return;
	}
	store.globalSpinnerStart();
	try {
		await store.switchTeam({ sid: id });
		closeSwitchDropDown();
		if (route.name === "dashboard") {
			bus.emit();
		} else {
			await navigateTo(Routes.Dashboard);
		}

		await resetData();
		emit("closeDropDown")

		toast(`Successfully switched to team ${name}`, {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
			position: "bottom-right",
		});
	} catch (error) {
		console.error("An error occurred while switching team:", error);
		toast("An error occurred while switching team", {
			theme: "auto",
			type: "warning",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
	} finally {
		store.globalSpinnerStop();
	}
	getShortcutKeys();
};

const switchTeamClicked = () => {
	teamMenuOpenId.value = -1;
	switchDropDownOpen.value = !switchDropDownOpen.value;
};

const deleteTeamClicked = (id) => {
	memTeams.value.forEach((ns, index) => {
		if (ns.team.id === id) {
			elementName.value = ns.team.name;
		}
	});
	elementId.value = id;
	teamMenuCliked(-1);
	isDeleteComponentModalActive.value = true;
};

const cancelDeleteModalClicked = (val) => {
	isDeleteComponentModalActive.value = false;
	if (val) {
		store.globalSpinnerStart();
		resetData().finally(() => {
			store.globalSpinnerStop();
		});
	}
};

const deleteTeam = async (id) => {
	try {
		teamLoader.value = true;
		const res = await store.deleteTeam({ sid: id });
		toast("Team deleted successfully", {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
		cancelDeleteModalClicked(true);
	} catch (err) {
		console.log("got error while deleting team : ", err);
		if (err.data?.status === "403") {
			toast("Deleting team from different account not allowed. ", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		} else {
			toast("Error occurred while deleting team :(", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		}
		cancelDeleteModalClicked(false);
	} finally {
		teamLoader.value = false;
	}
};

const memTeams = computed(() => {
	const data = store.getMemberTeams;
	if (data) {
		return data;
	}
	return false;
});

function getInitials(input) {
	if (typeof input !== "string" || input.trim() === "") {
		return "";
	}

	const words = input.trim().split(/\s+/);

	// If it's a single word
	if (words.length === 1) {
    const singleWord = words[0];
    // Extract the first letter and any subsequent capital letters, ensuring only letters are included
    const initials = singleWord
        .split("")
        .filter((char, index) => 
            (index === 0 || char === char.toUpperCase()) && /[a-zA-Z]/.test(char)
        )
        .join("");
    return initials.toUpperCase().slice(0, 2); // Get the first two letters
}

	// If it's two or more words, get initials of the first two words
	const firstTwoWordsInitials = words
		.slice(0, 2)
		.map((word) => word[0])
		.join("");
	return firstTwoWordsInitials.toUpperCase();
}
const isAccountPaid = computed(() => {
  const tier = store.$state?.authInfo?.account?.Tier ?? 0;
  const showBillingTab =
    store.$state?.authInfo?.account?.ShowBillingTab ?? true;
  if (tier !== 0 && tier !== 4 && tier !== 5 && showBillingTab) {
    return true;
  }
  return false;
});

const emit = defineEmits([
	"openBeamerIdeas",
	"openBeamerRoadmap",
    'closeDropDown'
]);

const openBeamerIdeas = () => {
	emit("openBeamerIdeas");
};

const openBeamerRoadmap = () => {
	emit("openBeamerRoadmap");
};

const closeDropDown = () => {
	switchDropDownOpen.value = false;
    emit('closeDropDown')
}

const resetData = async () => {
	store.globalSpinnerStart();
	switchDropDownOpen.value = false
	billingDetails.value = await sStore.getBillingDetails();
	await Promise.all([
		sStore.getBillingState(),
		store.fetchAllMemberTeams()
	]);
	store.globalSpinnerStop();
};

const signOut = async () => {
	store.globalSpinnerStart();
	await store.signOut();
	await navigateTo(Routes.Login);
	store.globalSpinnerStop();
};

// const enableDarkReader = () => {
//   document.documentElement.classList.toggle('dark-reader')
// }

onMounted(() => {
	resetData();
})

</script>
