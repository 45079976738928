<template>
	<div class="mb-14">
		<nav class="select-none bg-blue-900 fixed top-0 z-50 w-full force-blue">
			<div class="max-w-full mx-auto px-4 md:px-16">
				
				<div class="relative flex items-center justify-between h-14">
					<div
						class="absolute w-full inset-y-0 left-0 flex justify-between items-center md:hidden">
						<div
							@click.stop="routeToHome"
							class="cursor-pointer flex-shrink-0 flex items-center">
							<LogoSendXArrow class="h-7 w-auto" />
						</div>
						<!-- Mobile menu button-->
						<button
							@click="mobileMenuOpen = !mobileMenuOpen"
							type="button"
							class="flex items-center justify-center pl-2 rounded-md text-gray-200 hover:text-white focus:outline-none focus:ring-transparent focus:ring-offset-transparent"
							aria-controls="mobile-menu"
							aria-expanded="false">
							<span class="sr-only">Open main menu</span>
							<svg
								v-if="!mobileMenuOpen"
								class="h-7 w-7"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M4 6h16M4 12h16M4 18h16" />
							</svg>
							<!--
                  Icon when menu is open.
  
                  Heroicon name: outline/x
  
                  Menu open: "block", Menu closed: "hidden"
                -->
							<svg
								v-else
								class="h-7 w-7"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>
					<div
						class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
						<div class="hidden md:flex flex-row mr-2">
							<div
								@click.stop="routeToHome"
								class="mr-5 cursor-pointer flex-shrink-0 flex items-center">
								<LogoSendXArrow class="h-7 w-auto" />
							</div>
							<div
								v-click-outside="hideDropdowns"
								class="flex relative items-center">
								<div
									@click.stop="hideDropdownsAndRouteToDashboard"
									class="cursor-pointer active-tab flex h-8 items-center text-gray-200 hover:text-white px-3 text-sm hover:rounded-md">
									<span>Dashboard</span>
								</div>
								<div
									@click.stop="toggleDropdown(dropdowns.Campaigns)"
									:class="
										$route.fullPath.includes('/campaigns')
											? 'nuxt-link-active'
											: ''
									"
									class="active-tab flex h-8 items-center cursor-pointer text-gray-200 hover:text-white hover:rounded-md px-3 text-sm">
									<span class="flex flex-row items-center space-x-1">
										<span>Campaigns</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-4 w-4"
											viewBox="0 0 20 20"
											fill="currentColor">
											<path
												fill-rule="evenodd"
												d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												clip-rule="evenodd" />
										</svg>
									</span>
									<Transition
										enter-active-class="transition ease-out duration-100"
										enter-from-class="transform opacity-0 scale-95"
										enter-to-class="transform opacity-100 scale-100"
										leave-active-class="transition ease-in duration-75"
										leave-from-class="transform opacity-100 scale-100"
										leave-to-class="transform opacity-0 scale-95">
										<div
											v-if="activeDropdown === dropdowns.Campaigns">
											<NavDropdownCampaigns
												class="absolute z-30 -ml-28 mt-6" />
										</div>
									</Transition>
								</div>
								<div
									@click.stop="toggleDropdown(dropdowns.Audience)"
									:class="
										$route.fullPath.includes('/audience')
											? 'nuxt-link-active'
											: ''
									"
									class="active-tab flex h-8 items-center cursor-pointer text-gray-200 hover:text-white px-3 text-sm hover:rounded-md">
									<span class="flex flex-row items-center space-x-1">
										<span>Audience</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-4 w-4"
											viewBox="0 0 20 20"
											fill="currentColor">
											<path
												fill-rule="evenodd"
												d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												clip-rule="evenodd" />
										</svg>
									</span>
									<Transition
										enter-active-class="transition ease-out duration-100"
										enter-from-class="transform opacity-0 scale-95"
										enter-to-class="transform opacity-100 scale-100"
										leave-active-class="transition ease-in duration-75"
										leave-from-class="transform opacity-100 scale-100"
										leave-to-class="transform opacity-0 scale-95">
										<div
											v-if="activeDropdown === dropdowns.Audience"
											class="z-50">
											<NavDropdownAudience
												class="absolute z-30 -ml-28 mt-6" />
										</div>
									</Transition>
								</div>
								<nuxt-link
									:to="$config.public.routes.Form.Popup"
									class="flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
									<span
										class="cursor-pointer active-tab flex h-8 items-center text-gray-200 hover:text-white px-3 text-sm hover:rounded-md">
										<span>Forms</span>
									</span>
								</nuxt-link>
								<div
									@click.stop="toggleDropdown(dropdowns.Automate)"
									:class="
										$route.fullPath.includes('/automate')
											? 'nuxt-link-active'
											: ''
									"
									class="active-tab flex h-8 items-center cursor-pointer text-gray-200 hover:text-white px-3 text-sm hover:rounded-md">
									<span class="flex flex-row items-center space-x-1">
										<span>Automate</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-4 w-4"
											viewBox="0 0 20 20"
											fill="currentColor">
											<path
												fill-rule="evenodd"
												d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												clip-rule="evenodd" />
										</svg>
									</span>
									<Transition
										enter-active-class="transition ease-out duration-100"
										enter-from-class="transform opacity-0 scale-95"
										enter-to-class="transform opacity-100 scale-100"
										leave-active-class="transition ease-in duration-75"
										leave-from-class="transform opacity-100 scale-100"
										leave-to-class="transform opacity-0 scale-95">
										<div
											v-if="activeDropdown === dropdowns.Automate"
											class="z-50">
											<NavDropdownAutomate
												class="absolute z-30 -ml-28 mt-6" />
										</div>
									</Transition>
								</div>
								<nuxt-link
									v-if="store.$state.authInfo?.account?.IsAdmin"
									:to="$config.public.routes.Admin"
									class="flex h-8 items-center text-gray-200 hover:text-white hover:rounded-md px-3 text-sm">
									<span
										class="cursor-pointer active-tab flex h-8 items-center text-gray-200 hover:text-white hover:rounded-md px-3 text-sm">
										<span>Admin</span>
									</span>
								</nuxt-link>
							</div>
						</div>
					</div>
					<div
						class="hidden md:flex absolute inset-y-0 right-0 items-center pr-2 sm:static sm:inset-auto sm:pr-0">
						<button
							v-if="
								settingStore.$state.accountState ===
									AccountState.CardInvalid && isAccountPaid
							"
							@click.stop="upgradeClicked"
							type="button"
							class="hidden lg:inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							<span>Invalid Card</span>
						</button>
						<div v-else-if="isAccountPaid"></div>
						<button
							v-else-if="
								store.checkVisibility({ entity: AppEntities.Billing })
							"
							@click.stop="upgradeClicked"
							type="button"
							class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-emerald-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-emerald-500 focus:outline-none focus:border-emerald-700 focus:shadow-outline-emerald transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							<span>Upgrade</span>
						</button>
						<div class="h-14 border-l border-white opacity-10 ml-3"></div>
						<div class="flex flex-row items-center space-x-3 pl-3">
							<button
								v-if="
									store.checkVisibility({
										entity: AppEntities.Contact,
									})
								"
								@click.stop="videoSearchClicked"
								type="button"
								class="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
								<span class="sr-only">Video Tutorial</span>
								<!-- Heroicon name: outline/bell -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									width="35"
									height="35"
									viewBox="0 0 48 48">
									<path
										fill="#fff"
										d="M43.102,33.9c-0.399,2.1-2.095,3.7-4.19,4C35.621,38.4,30.135,39,23.95,39	c-6.085,0-11.571-0.6-14.963-1.1c-2.095-0.3-3.791-1.9-4.19-4C4.399,31.6,4,28.2,4,24s0.399-7.6,0.798-9.9	c0.399-2.1,2.095-3.7,4.19-4C12.279,9.6,17.766,9,23.95,9s11.571,0.6,14.963,1.1c2.095,0.3,3.791,1.9,4.19,4	C43.501,16.4,44,19.8,44,24C43.9,28.2,43.501,31.6,43.102,33.9z"></path>
									<path
										fill="#1e3a8a"
										d="M34,21c-0.33,0.17-0.67,0.33-1,0.5c-1.33,0.67-2.67,1.33-4,2c-1.67,0.83-3.34,1.66-5,2.5 c-1.66-0.84-3.33-1.67-5-2.5c-1.67-0.83-3.34-1.66-5-2.5c3.33-1.33,6.67-2.67,10-4C27.33,18.33,30.67,19.67,34,21z"></path>
									<path
										fill="#1e3a8a"
										d="M29,23v0.5c-1.67,0.83-3.34,1.66-5,2.5c-1.66-0.84-3.33-1.67-5-2.5V23H29z"></path>
									<path
										fill="#1e3a8a"
										d="M24.89,27.79L29,25.74c0,1.753,0,3.507,0,5.26c-3.333,0-6.667,0-10,0c0-1.753,0-3.507,0-5.26	l4.11,2.05L24,28.24L24.89,27.79z"></path>
									<path
										fill="none"
										stroke="#1e3a8a"
										stroke-miterlimit="10"
										stroke-width="2"
										d="M33,21c0,0.17,0,0.33,0,0.5c0,0.75,0,1.49,0,2.24	c0,1.09,0,2.17,0,3.26"></path>
								</svg>
							</button>
							<button
								v-if="
									store.checkVisibility({
										entity: AppEntities.Contact,
									})
								"
								@click.stop="contactSearchClicked"
								type="button"
								class="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
								<span class="sr-only">Search</span>
								<!-- Heroicon name: outline/bell -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd" />
								</svg>
							</button>
							<button
								type="button"
								class="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white beamerTrigger">
								<span class="sr-only">View notifications</span>
								<!-- Heroicon name: outline/bell -->
								<svg
									class="h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true">
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
								</svg>
							</button>
						</div>

						<div class="h-14 border-l border-white opacity-10 ml-3"></div>

						<!-- Profile dropdown -->
						<div
							v-click-outside="closeDropDown"
							class="pl-3 relative">
							<div>
								<button
									@click.stop="
										profileDropDownOpen = !profileDropDownOpen;
									"
									type="button"
									class="bg-white flex text-sm rounded-full focus:outline-none focus:ring-transparent focus:ring-offset-transparent"
									id="user-menu-button"
									aria-expanded="false"
									aria-haspopup="true">
									<span class="sr-only">Open user menu</span>
									<div class="relative">
										<div
											class="h-10 w-10 flex flex-col justify-center items-center p-2 bg-blue-500 rounded-full">
											<h1 class="text-white text-sm">
												{{ getInitials(currentTeam?.name) }}
											</h1>
										</div>
										<img
											class="absolute border-2 border-gray-50 h-3 w-3 rounded-full bottom-0 -right-[1px]"
											:src="
												store.$state.memberAvatarUrl != ''
													? store.$state.memberAvatarUrl
													: tempAvatarUrl
											"
											alt="" />
									</div>
								</button>
							</div>

							<NavbarProfileDropdown
								:profile-drop-down-open="profileDropDownOpen"
								:is-account-paid="isAccountPaid"
								@open-beamer-ideas="openBeamerIdeas"
								@open-beamer-roadmap="openBeamerRoadmap"
								@closeDropDown="closeDropDown" />
						</div>
					</div>
				</div>
			</div>

			<!-- Mobile menu, show/hide based on menu state. -->
			<transition
				v-click-outdside="closeMobileMenu"
				enter-active-class="transition ease-out duration-100"
				enter-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100"
				leave-active-class="transition ease-in duration-75"
				leave-class="transform opacity-100 scale-100"
				leave-to-class="transform opacity-0 scale-95">
				<div
					v-if="mobileMenuOpen"
					class="absolute bg-blue-900 w-full z-20 md:hidden"
					id="mobile-menu">
					<div
						class="px-2 pt-2 pb-3 flex flex-col space-y-1 border-b border-blue-800">
						<nuxt-link
							@click.stop="closeMobileMenu"
							exact
							:to="$config.public.routes.Dashboard"
							class="w-fit active-tab text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							Dashboard
						</nuxt-link>
						<nuxt-link
							@click.stop="toggleDropdown(dropdowns.Campaigns)"
							:to="$config.public.routes.Campaign.Newsletter.Home"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-1">
								<span>Campaigns</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd" />
								</svg>
							</span>
						</nuxt-link>
						<Transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div v-if="activeDropdown === dropdowns.Campaigns">
								<NavDropdownCampaigns
									@click.stop="closeMobileMenu"
									class="absolute z-30 -ml-0 mt-1" />
							</div>
						</Transition>
						<a
							@click.stop="toggleDropdown(dropdowns.Audience)"
							href="#"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-1">
								<span>Audience</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd" />
								</svg>
							</span>
						</a>
						<Transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="activeDropdown === dropdowns.Audience"
								class="z-50">
								<NavDropdownAudience
									@click.stop="closeMobileMenu"
									class="absolute z-30 -ml-0 mt-1" />
							</div>
						</Transition>
						<nuxt-link
							@click.stop="closeMobileMenu"
							:to="$config.public.routes.Form.Popup"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-1">
								<span>Forms</span>
							</span>
						</nuxt-link>
						<a
							@click.stop="toggleDropdown(dropdowns.Automate)"
							href="#"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-1">
								<span>Automate</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clip-rule="evenodd" />
								</svg>
							</span>
						</a>
						<Transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="activeDropdown === dropdowns.Automate"
								class="z-50">
								<NavDropdownAutomate
									@click.stop="closeMobileMenu"
									class="absolute z-30 -ml-0 mt-1" />
							</div>
						</Transition>

						<nuxt-link
							v-if="store.$state.authInfo?.account?.IsAdmin"
							:to="$config.public.routes.Admin"
							class="flex h-14 items-center text-gray-200 hover:text-white text-sm">
							<span
								class="cursor-pointer active-tab flex h-14 items-center text-gray-200 hover:text-white px-3 text-sm">
								<span>Admin</span>
							</span>
						</nuxt-link>
					</div>

					<div
						class="px-2 pt-2 pb-3 flex flex-col space-y-1 border-b border-blue-800">
						<a
							v-if="
								store.checkVisibility({ entity: AppEntities.Contact })
							"
							@click.stop="contactSearchClickedMobile"
							href="#"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-2">
								<!-- Heroicon name: outline/bell -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd" />
								</svg>
								<span>Search</span>
							</span>
						</a>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="searchDropDownOpenMobile"
								v-click-outside="closeContactSearchMobile"
								class="z-20 absolute mt-24 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
								tabindex="-1"
								role="listbox"
								aria-labelledby="listbox-label"
								ref="optionsRef">
								<div class="p-2.5 flex items-center">
									<input
										autocomplete="off"
										data-lpignore="true"
										v-model="searchValue"
										type="text"
										name="firstName"
										id="firstName"
										class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
										placeholder="Search contact by email ..." />

									<svg
										@click.stop="searchContact"
										class="ml-2 w-8 h-8 cursor-pointer"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<g
											id="SVGRepo_bgCarrier"
											stroke-width="0"></g>
										<g
											id="SVGRepo_tracerCarrier"
											stroke-linecap="round"
											stroke-linejoin="round"></g>
										<g id="SVGRepo_iconCarrier">
											<path
												d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z"
												stroke="#000000"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"></path>
										</g>
									</svg>
								</div>
								<ul class="mt-2">
									<li
										v-if="queryInProgress"
										class="font-semibold px-3 pb-1 text-md">
										Loading Contacts ...
									</li>
									<li
										v-else-if="
											!queryInProgress &&
											filteredContact?.length > 0 &&
											filteredContact?.[0].id > 0
										"
										class="cursor-pointer select-none px-2 text-sm"
										role="option">
										<span
											@click.stop="
												gotoContact(filteredContact?.[0].id)
											"
											class="font-semibold block truncate shadow-sm w-full pl-4 mx-2 py-2 hover:shadow-md rounded-md">
											{{ filteredContact?.[0].email }}
										</span>
									</li>
									<li
										v-else-if="
											!queryInProgress &&
											filteredContact?.[0].id === 0
										"
										class="font-semibold px-3 pb-1 text-md">
										No contacts found
									</li>
								</ul>
							</div>
						</transition>
					</div>

					<div
						v-click-outside="closeDropDownMobile"
						class="relative px-2 pt-2 pb-3 flex flex-col space-y-1 border-b border-blue-800">
						<a
							href="#"
							@click.stop="handleProfileClick"
							class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium">
							<span class="flex flex-row items-center space-x-2">
								<img
									class="h-8 w-8 rounded-full"
									:src="
										store.$state.memberAvatarUrl != ''
											? store.$state.memberAvatarUrl
											: tempAvatarUrl
									"
									alt="" />
								<div class="text-white text-sm leading-5 font-normal">
									{{ currentTeam?.name }}
								</div>
							</span>
						</a>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="profileDropDownOpenMobile"
								class="z-20 absolute  mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="user-menu-button"
								tabindex="-1">
								<div class="flex flex-col divide-y divide-y-gray-50">
									<div
										class="flex px-4 py-2 flex-row space-x-4 items-center">
										<div>
											<span class="inline-block relative">
												<img
													class="h-10 w-10 rounded-full"
													:src="
														store.$state.memberAvatarUrl != ''
															? store.$state.memberAvatarUrl
															: tempAvatarUrl
													"
													alt="" />
												<span
													class="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
											</span>
										</div>
										<div class="flex flex-col justify-between">
											<div
												class="text-gray-800 text-sm leading-5 font-medium">
												{{ currentTeam?.name }}
											</div>
											<div class="text-gray-400 text-sm">
												{{
													currentTeam?.companyName !== ""
														? currentTeam?.companyName
														: "-"
												}}
											</div>
										</div>
									</div>

									<div class="flex flex-col">
										<nuxt-link
											@click.stop="closeMobileMenu"
											:to="$config.public.routes.Profile"
											class="text-sm px-4 py-3 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1"
											id="user-menu-item-0">
											<div
												class="flex flex-row items-center space-x-4">
												<span class="text-sm leading-5 font-normal"
													>My Profile</span
												>
											</div>
										</nuxt-link>

										<nuxt-link
											@click.stop="closeMobileMenu"
											:to="$config.public.routes.Setting.Setup.DomainWhitelisting"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div
												class="flex flex-row items-center space-x-4">
												<span class="text-sm leading-5 font-normal"
													>Settings</span
												>
											</div>
										</nuxt-link>
									</div>

									<div @click.stop="closeMobileMenu" class="flex flex-col">
										<a
											target="_blank"
											rel="noreferrer"
											href="https://sendworks.com/support/sendx"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div class="flex flex-row items-center justify-between">
												<span class="text-sm leading-5 font-normal">
													Knowledge Base
												</span>
												<span>
													<svg
														width="11"
														height="11"
														viewBox="0 0 11 11"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M10.0762 0.402344C10.4824 0.402344 10.8262 0.746094 10.8262 1.15234V8.65234C10.8262 9.08984 10.4824 9.40234 10.0762 9.40234C9.63867 9.40234 9.32617 9.08984 9.32617 8.65234V2.96484L2.10742 10.1836C1.79492 10.4961 1.32617 10.4961 1.04492 10.1836C0.732422 9.90234 0.732422 9.43359 1.04492 9.15234L8.26367 1.93359L2.57617 1.90234C2.13867 1.90234 1.82617 1.58984 1.82617 1.15234C1.82617 0.746094 2.13867 0.402344 2.57617 0.402344H10.0762Z"
															fill="#6B7280" />
													</svg>
												</span>
											</div>
										</a>

										<nuxt-link
											@click.stop="closeMobileMenu"
											:to="$config.public.routes.Tickets.Home"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-2">
											<div class="flex flex-row items-center justify-between space-x-4">
												<span class="text-sm leading-5 font-normal"
													>Create Ticket</span
												>
												<span>
													<svg
														width="11"
														height="11"
														viewBox="0 0 11 11"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M10.0762 0.402344C10.4824 0.402344 10.8262 0.746094 10.8262 1.15234V8.65234C10.8262 9.08984 10.4824 9.40234 10.0762 9.40234C9.63867 9.40234 9.32617 9.08984 9.32617 8.65234V2.96484L2.10742 10.1836C1.79492 10.4961 1.32617 10.4961 1.04492 10.1836C0.732422 9.90234 0.732422 9.43359 1.04492 9.15234L8.26367 1.93359L2.57617 1.90234C2.13867 1.90234 1.82617 1.58984 1.82617 1.15234C1.82617 0.746094 2.13867 0.402344 2.57617 0.402344H10.0762Z"
															fill="#6B7280" />
													</svg>
												</span>
											</div>
										</nuxt-link>

										<div
											@click.stop="openBeamerIdeas"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100">
											<div
												class="flex flex-row items-center space-x-4">
												<span class="text-sm leading-5 font-normal"
													>Feature Request</span
												>
											</div>
										</div>

										<div
											@click="openBeamerRoadmap"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100">
											<div class="flex flex-row items-center space-x-4">
												<span class="text-sm leading-5 font-normal"
													>Product Roadmap</span
												>
											</div>
										</div>
										<a
											target="_blank"
											rel="noreferrer"
											href="https://docs.sendx.io/"
											class="text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div
												class="flex flex-row items-center justify-between">
												<span class="text-sm leading-5 font-normal"
													>API</span
												>
												<span>
													<svg
														width="11"
														height="11"
														viewBox="0 0 11 11"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M10.0762 0.402344C10.4824 0.402344 10.8262 0.746094 10.8262 1.15234V8.65234C10.8262 9.08984 10.4824 9.40234 10.0762 9.40234C9.63867 9.40234 9.32617 9.08984 9.32617 8.65234V2.96484L2.10742 10.1836C1.79492 10.4961 1.32617 10.4961 1.04492 10.1836C0.732422 9.90234 0.732422 9.43359 1.04492 9.15234L8.26367 1.93359L2.57617 1.90234C2.13867 1.90234 1.82617 1.58984 1.82617 1.15234C1.82617 0.746094 2.13867 0.402344 2.57617 0.402344H10.0762Z"
															fill="#6B7280" />
													</svg>
												</span>
											</div>
										</a>
									</div>

									<div class="flex flex-col">
										<div
											@click.stop="switchTeamClickedMobile"
											class="cursor-pointer text-sm px-4 py-3 text-gray-700 hover:bg-gray-100 flex flex-row justify-between items-center"
											role="menuitem"
											tabindex="-1">
											<div class="flex flex-row items-center space-x-4">
												<span class="text-sm leading-5 font-normal"
													>Switch Team</span
												>
											</div>
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 text-gray-400"
													viewBox="0 0 20 20"
													fill="currentColor">
													<path
														fill-rule="evenodd"
														d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
														clip-rule="evenodd" />
												</svg>
											</div>
										</div>
									</div>

									<div class="flex flex-col">
										<div
											@click.stop="signOut"
											class="cursor-pointer text-sm px-4 py-2 text-gray-700 hover:bg-gray-100"
											role="menuitem"
											tabindex="-1">
											<div class="flex flex-row items-center space-x-4">
												<span class="text-sm leading-5 font-normal"
													>Sign out</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</transition>
						<transition v-click-outside="closeDropDown"
							enter-active-class="transition ease-out duration-100"
							enter-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div
								v-if="switchDropDownOpenMobile"
								class="z-20 absolute mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="user-menu-button"
								tabindex="-1">
								<div class="h-[40vh] overflow-y-auto">
									<div v-for="(sa, ind) in memTeams">
										<div
											@click.stop="switchTeam(sa.team.id, sa.team.name)"
											class="flex flex-col divide-y divide-y-gray-50 cursor-pointer">
											<div class="relative group">
												<div
													class="flex justify-between"
													:class="[
														sa.team.id === currentTeam?.id
															? 'bg-green-200'
															: 'hover:bg-gray-100',
													]">
													<div
														class="px-4 py-2 flex space-x-4 items-center">
														<div
															class="flex flex-col justify-between">
															<div
																class="text-gray-800 leading-5"
																:class="[
																	sa.team.id === currentTeam?.id
																		? 'font-semibold text-md'
																		: 'text-sm font-medium',
																]">
																{{ sa.team.name }}
															</div>
															<div
																class="text-gray-400 leading-5 text-xs"
																:class="[
																	sa.team.id === currentTeam?.id
																		? 'font-semibold'
																		: 'font-medium',
																]">
																{{
																	sa.team.companyName !== ""
																		? sa.team.companyName
																		: "-"
																}}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="mb-10"></div>

									<div @click.stop="manageTeamsClicked" class="border-t py-3 px-3 text-sm text-blue-400 absolute bottom-0 bg-white w-full rounded-b-md cursor-pointer">
										Manage teams
									</div>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</transition>
		</nav>

		<SettingPaymentUpgradeModal
			:active="isComponentModalActive"
			:selectedPrice="selectedPrice"
			:selectedMonthlyPrice="selectedMonthlyPrice"
			:isDiscountAvailabel="isDiscountAvailabel"
			:discount="discount"
			:isMonthlyBillingType="isMonthlyBillingType"
			:selectedOption="selectedOption"
			:options="options"
			@cancelModalClicked="cancelModalClicked"
			@handlePurchase="handlePurchase"
			@toggleBillingType="(e) => toggleBillingType(e)"
			@handleDropdownInput="(e) => handleDropdownInput(e)" />

		<DeleteModalForm
			:active="isDeleteComponentModalActive"
			:entityName="entityName"
			:elementId="elementId"
			:elementName="elementName"
			:deleteLoader="teamLoader"
			@cancelModalClicked="cancelDeleteModalClicked"
			@deleteModalClicked="(value) => deleteTeam(value)" />
	</div>
</template>

<script setup>
import {
	Routes,
	AccountState,
	stripePublicKey,
	AppEntities,
	AddOns,
} from "~/constants";
import { useEventBus } from "@vueuse/core";
import {
	formatUtcOffset,
	getTimeZoneGroups,
	getTimeZoneInfo,
} from "~/data/timezone";
import { toLower } from "lodash";
import moment from "moment-timezone";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const dropdowns = ref({
	None: "none",
	Campaigns: "campaigns",
	Audience: "audience",
	Automate: "automate",
	Workflows: "workflows",
	LeadCapture: "lead-capture",
});

const switchDropDownOpenMobile = ref(false);
const entityName = ref("Team");
const elementId = ref(0);
const elementName = ref("");
const isDeleteComponentModalActive = ref(false);
const teamLoader = ref(false);
const store = useGlobalStore();
const settingStore = useSettingStore();
const contactStore = useAudienceContactStore();
const profileDropDownOpen = ref(false);
const mobileMenuOpen = ref(false);
const activeDropdown = ref(dropdowns.value.None);
const searchDropDownOpen = ref(false);
const searchDropDownOpenMobile = ref(false);
const searchValue = ref("");
const queryInProgress = ref(false);
const filteredContact = ref(null);
const teamMenuOpenId = ref(-1);
const teamMenuOpen = ref(false);
let now = Date.now();
const tempAvatarUrl =
	"https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

const addOnStore = useAddOnStore();
const profileDropDownOpenMobile = ref(false);
const cookies = ref("");
const route = useRoute()
const bus = useEventBus("resetData");

const currentTeam = computed(() => {
	return store.currentTeam
})

const isShortcutKeyEnabled = computed(() => {
  return addOnStore.isAddOnEnabled({entity: AddOns.SHORTCUTS})
});

const manageTeamsClicked = async () => {
	await navigateTo(Routes.Team)
	closeDropDown();
	closeMobileMenu()
}

const handleProfileClick = () => {
	profileDropDownOpenMobile.value = !profileDropDownOpenMobile.value;
};

const teamMenuCliked = (ind) => {
	if (teamMenuOpen.value === false) {
		teamMenuOpenId.value = ind;
		teamMenuOpen.value = true;
	} else {
		if (teamMenuOpenId.value !== -1 && teamMenuOpenId.value !== ind) {
			teamMenuOpenId.value = ind;
			teamMenuOpen.value = true;
		} else {
			teamMenuOpenId.value = -1;
			teamMenuOpen.value = false;
		}
	}
};

const cancelDeleteModalClicked = (val) => {
	isDeleteComponentModalActive.value = false;
	if (val) {
		store.globalSpinnerStart();
		resetData().finally(() => {
			store.globalSpinnerStop();
		});
	}
};

const getShortcutKeys = async () => {
	try {
		const data = await store.getShortcutKeys()
		console.log(data, "shortcut keys");
	} catch (error) {
		console.log(error);
	}
}

const switchTeam = async (id, name) => {
	if (id === currentTeam.value?.id) {
		return;
	}
	store.globalSpinnerStart();
	try {
		await store.switchTeam({ sid: id });
		if (route.name === "dashboard") {
			bus.emit();
		} else {
			await navigateTo(Routes.Dashboard);
		}

		await resetData();
		closeDropDown();
		closeMobileMenu()

		toast(`Successfully switched to team ${name}`, {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
			position: "bottom-right",
		});
	} catch (error) {
		console.error("An error occurred while switching team:", error);
		toast("An error occurred while switching team", {
			theme: "auto",
			type: "warning",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
	} finally {
		store.globalSpinnerStop();
	}
	getShortcutKeys();
};

const deleteTeam = async (id) => {
	try {
		teamLoader.value = true;
		const res = await store.deleteTeam({ sid: id });
		toast("Team deleted successfully", {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
		cancelDeleteModalClicked(true);
	} catch (err) {
		console.log("got error while deleting team : ", err);
		if (err.data?.status === "403") {
			toast("Deleting team from different account not allowed. ", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		} else {
			toast("Error occurred while deleting team :(", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		}
		cancelDeleteModalClicked(false);
	} finally {
		teamLoader.value = false;
	}
};

const deleteTeamClicked = (id) => {
	memTeams.value.forEach((ns, index) => {
		if (ns.team.id === id) {
			elementName.value = ns.team.name;
		}
	});
	elementId.value = id;
	teamMenuCliked(-1);
	isDeleteComponentModalActive.value = true;
};

const memTeams = computed(() => {
	const data = store.getMemberTeams;
	if (data) {
		return data;
	}
	return false;
});

function getInitials(input) {
	if (typeof input !== "string" || input.trim() === "") {
		return "";
	}

	const words = input.trim().split(/\s+/);

	// If it's a single word
	if (words.length === 1) {
		const singleWord = words[0];
		// Extract the first letter and any subsequent capital letters, ensuring only letters are included
		const initials = singleWord
			.split("")
			.filter(
				(char, index) =>
					(index === 0 || char === char.toUpperCase()) &&
					/[a-zA-Z]/.test(char)
			)
			.join("");
		return initials.toUpperCase().slice(0, 2); // Get the first two letters
	}

	// If it's two or more words, get initials of the first two words
	const firstTwoWordsInitials = words
		.slice(0, 2)
		.map((word) => word[0])
		.join("");
	return firstTwoWordsInitials.toUpperCase();
}

const isAccountPaid = computed(() => {
	const tier = store.$state?.authInfo?.account?.Tier ?? 0;
	const showBillingTab =
		store.$state?.authInfo?.account?.ShowBillingTab ?? true;
	if (tier !== 0 && tier !== 4 && tier !== 5 && showBillingTab) {
		return true;
	} else {
		return false;
	}
});

function openBeamerIdeas() {
	if (window.Beamer) {
		window.Beamer.showIdeas(true);
	} else {
		console.log("Beamer is not loaded yet.");
	}
	closeDropDown();
	closeMobileMenu()
}

function openBeamerRoadmap() {
	if (window.Beamer) {
		window.Beamer.showRoadmap();
	} else {
		console.log("Beamer is not loaded yet.");
	}
	closeDropDown();
	closeMobileMenu()
}

const hideDropdownsAndRouteToDashboard = async () => {
	hideDropdowns();
	await navigateTo(Routes.Dashboard);
};

const routeToHome = async () => {
	await navigateTo(Routes.Home);
};

const signOut = async () => {
	store.globalSpinnerStart();
	await store.signOut();
	await navigateTo(Routes.Login);
	store.globalSpinnerStop();
};

const closeDropDown = () => {
	profileDropDownOpen.value = false;
};

const closeDropDownMobile = () => {
	console.log("closeDropDownMobile");
	profileDropDownOpenMobile.value = false;
	switchDropDownOpenMobile.value = false;
};

const closeMobileMenu = () => {
	profileDropDownOpenMobile.value = false
	switchDropDownOpenMobile.value = false
	mobileMenuOpen.value = false;
};

const toggleDropdown = (dd) => {
	activeDropdown.value =
		activeDropdown.value === dd ? dropdowns.value.None : dd;
};

const hideDropdowns = () => {
	activeDropdown.value = dropdowns.value.None;
};

const switchTeamClickedMobile = () => {
	profileDropDownOpenMobile.value = false;
	teamMenuOpenId.value = -1;
	switchDropDownOpenMobile.value = !switchDropDownOpenMobile.value;
};

const closeContactSearch = () => {
	searchDropDownOpen.value = false;
	filteredContact.value = null;
	searchValue.value = "";
};

const closeContactSearchMobile = () => {
	searchDropDownOpenMobile.value = false;
	filteredContact.value = null;
	searchValue.value = "";
};

const contactSearchClicked = (e) => {
	console.log("contactSearchClicked", e)
	store.setResource({ resource: "showSearch", value: !store.$state.showSearch });
	store.setResource({ resource: "searchTabSelected", value: 2 });
	searchDropDownOpen.value = !searchDropDownOpen.value;
};

const videoSearchClicked = () => {
	store.setResource({ resource: "showSearch", value: !store.$state.showSearch });
	store.setResource({ resource: "searchTabSelected", value: 1 });
	
	searchDropDownOpen.value = !searchDropDownOpen.value;
}

const contactSearchClickedMobile = () => {
	searchDropDownOpenMobile.value = !searchDropDownOpenMobile.value;
};

const isEmail = () => {
	var regex =
		/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if (!regex.test(searchValue.value)) {
		return false;
	} else {
		return true;
	}
};

const searchContact = async () => {
	if (isEmail()) {
		if (queryInProgress.value === false) {
			queryInProgress.value = true;

			try {
				const res = await contactStore.fetchContactByEmail({
					search: searchValue.value.toLowerCase(),
				});
				filteredContact.value = [];
				if (res !== null) {
					filteredContact.value.push(res);
				}

				queryInProgress.value = false;
			} catch (err) {
				toast("Error occurred while getting contact by email", {
					theme: "auto",
					type: "error",
					hideProgressBar: false,
					autoClose: 3000,
					transition: "slide",
				});
				searchValue.value = "";
				queryInProgress.value = false;
				filteredContact.value = null;
			}
		} else {
			toast("We are still fetching results of your last search.", {
				theme: "auto",
				type: "warning",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		}
	} else {
		toast("Please enter a valid email", {
			theme: "auto",
			type: "error",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
	}
};

const gotoContact = async (id) => {
	closeContactSearch();
	closeMobileMenu();
	await navigateTo(`${Routes.Audience.Contact.Info.Home}/${id}`);
};

//        ----- Billing Code --------       //
const selectedOption = ref(0);
const isMonthlyBillingType = ref(true);
const monthlyContactDiscount = ref(0);
const yearlyContactDiscount = ref(25);
const yearlyDefaultDiscount = ref(false);
const businessPriceAmount = ref(999);
const totalAmount = ref(999);
const isComponentModalActive = ref(false);
const description = ref("Business Plan ( 1000 contacts )");
const label = ref("Upgrade for");
const paymentType = ref("Upgrade");

const options = [
	{ id: 0, contact: 1000 },
	{ id: 1, contact: 2500 },
	{ id: 2, contact: 5000 },
	{ id: 3, contact: 10000 },
	{ id: 4, contact: 15000 },
	{ id: 5, contact: 20000 },
	{ id: 6, contact: 25000 },
	{ id: 7, contact: 30000 },
	{ id: 8, contact: 35000 },
	{ id: 9, contact: 40000 },
	{ id: 10, contact: 45000 },
	{ id: 11, contact: 50000 },
	{ id: 12, contact: 60000 },
	{ id: 13, contact: 70000 },
	{ id: 14, contact: 80000 },
	{ id: 15, contact: 90000 },
	{ id: 16, contact: 100000 },
	{ id: 17, contact: 120000 },
	{ id: 18, contact: 140000 },
	{ id: 19, contact: 160000 },
	{ id: 20, contact: 180000 },
	{ id: 21, contact: 200000 },
	{ id: 22, contact: 250000 },
	{ id: 23, contact: 300000 },
	{ id: 24, contact: 350000 },
	{ id: 25, contact: 400000 },
	{ id: 26, contact: 450000 },
	{ id: 27, contact: 500000 },
	{ id: 28, contact: 600000 },
	{ id: 29, contact: 800000 },
	{ id: 30, contact: 1000000 },
	{ id: 31, contact: 1500000 },
	{ id: 32, contact: 2000000 },
];

const availabelPlans = [
	"free_plan",
	"growth_plan",
	"custom_plan",
	"business_plan",
	"enterprise_plan",
	"free_trial_plan",
	"agency_plan",
];

var PROMO_CODE_BOTH = 0;
var PROMO_CODE_ONLY_MONTHLY = 1;
var PROMO_CODE_ONLY_YEARLY = 2;

const monthlyBusinessPriceAmounts = [
	999, 1999, 3999, 5999, 7999, 11999, 14999, 17999, 18999, 19999, 20999, 22999,
	27999, 29999, 31999, 35999, 39999, 44999, 49999, 54999, 59999, 64999, 79999,
	89999, 99999, 119999, 139999, 159999, 199999, 249999, 299999, 399999, 499999,
];

const toInt = (n) => {
	return Math.round(Number(n));
};

const isDiscountAvailabel = computed(() => {
	if (isMonthlyBillingType.value && monthlyContactDiscount.value > 0) {
		return true;
	} else if (!isMonthlyBillingType.value && yearlyContactDiscount.value > 0) {
		return true;
	} else {
		return false;
	}
});

const discount = computed(() => {
	if (isMonthlyBillingType.value && monthlyContactDiscount.value > 0) {
		return monthlyContactDiscount.value;
	} else if (!isMonthlyBillingType.value && yearlyContactDiscount.value > 0) {
		return yearlyContactDiscount.value;
	} else {
		return 0;
	}
});

const toggleBillingType = () => {
	isMonthlyBillingType.value = !isMonthlyBillingType.value;
	let planDuration = "Monthly";
	if (!isMonthlyBillingType.value) {
		planDuration = "Yearly";
		totalAmount.value = 12 * businessPriceAmount.value;
		totalAmount.value = totalAmount.value * ((100 - discount.value) / 100);
	} else {
		totalAmount.value = businessPriceAmount.value;
	}
	const newLP = {
		...settingStore.$state.paymentForm,
		planDuration: planDuration,
		price: totalAmount.value.toString(),
	};
	settingStore.setResource({ resource: "paymentForm", value: newLP });
};

const handleDropdownInput = (e) => {
	selectedOption.value = e;

	description.value =
		"Business Plan ( " +
		options[selectedOption.value].contact +
		" contacts )";

	if (isMonthlyBillingType.value) {
		businessPriceAmount.value =
			monthlyBusinessPriceAmounts[selectedOption.value] -
			toInt(
				(monthlyContactDiscount.value *
					monthlyBusinessPriceAmounts[selectedOption.value]) /
					100
			);
		totalAmount.value = businessPriceAmount.value;
	} else {
		businessPriceAmount.value =
			(monthlyBusinessPriceAmounts[selectedOption.value] * 12 -
				toInt(
					(yearlyContactDiscount.value *
						(monthlyBusinessPriceAmounts[selectedOption.value] * 12)) /
						100
				)) /
			12;
		totalAmount.value = 12 * businessPriceAmount.value;
	}

	const newLP = {
		...settingStore.$state.paymentForm,
		contacts: options[selectedOption.value].contact.toString(),
		price: totalAmount.value.toString(),
	};
	settingStore.setResource({ resource: "paymentForm", value: newLP });
};

const selectedMonthlyPrice = computed(() => {
	if (selectedOption.value >= 0 && selectedOption.value < options.length) {
		let amount =
			monthlyBusinessPriceAmounts[selectedOption.value] -
			toInt(
				(monthlyContactDiscount.value *
					monthlyBusinessPriceAmounts[selectedOption.value]) /
					100
			);
		return parseFloat(amount / 100.0).toFixed(2);
	}
	return "...";
});

const selectedPrice = computed(() => {
	if (selectedOption.value >= 0 && selectedOption.value < options.length) {
		let amount = 0;
		if (isMonthlyBillingType.value) {
			amount =
				monthlyBusinessPriceAmounts[selectedOption.value] -
				toInt(
					(monthlyContactDiscount.value *
						monthlyBusinessPriceAmounts[selectedOption.value]) /
						100
				);
		} else {
			amount =
				(monthlyBusinessPriceAmounts[selectedOption.value] * 12 -
					toInt(
						(yearlyContactDiscount.value *
							(monthlyBusinessPriceAmounts[selectedOption.value] * 12)) /
							100
					)) /
				12;
		}
		return parseFloat(amount / 100.0).toFixed(2);
	}
	return "...";
});

const getCurrentPlan = computed(() => {
	const tier = store.$state?.authInfo?.account?.Tier ?? 0;
	return availabelPlans[tier];
});

const getCurrentBillingDuration = computed(() => {
	return settingStore.$state.billingDuration;
});

const getCookieValue = (name) => {
	const match = cookies.value.match(new RegExp("(^|; )" + name + "=([^;]*)"));
	return match ? decodeURIComponent(match[2]) : null;
};

//          -----   stripe code   -----
var paymentHandler = StripeCheckout.configure({
	key: stripePublicKey(),
	image: `/static_source/img/apple-icon-180x180.png`, // rendering from frontend public resource
	locale: "auto",
	token: function (token) {
		// You can access the token ID with `token.id`.
		// Get the token ID to your server-side code for use.
		store.globalSpinnerStart();
		const newLP = {
			...settingStore.$state.paymentForm,
			stripeToken: token.id,
			hutk: getCookieValue("hubspotutk"),
		};
		settingStore.setResource({ resource: "paymentForm", value: newLP });

		settingStore
			.purchasePlan()
			.then((data) => {
				store.globalSpinnerStop();
				toast("Successfully Upgraded", {
					theme: "auto",
					type: "success",
					hideProgressBar: false,
					transition: "slide",
				});
			})
			.catch((err) => {
				console.log("some error occured", err);
				store.globalSpinnerStop();
				toast("Payment Failed", {
					theme: "auto",
					type: "error",
					hideProgressBar: false,
					transition: "slide",
				});
			});
	},
});

const handlePurchase = () => {
	isComponentModalActive.value = false;
	initStripePayment();
};

const cancelModalClicked = () => {
	isComponentModalActive.value = false;
};

const upgradeClicked = async () => {
	settingStore.resetPaymentForm();
	await settingStore.getBillingState();

	// Populate params according to account state
	if (settingStore.$state.accountState !== "") {
		if (settingStore.$state.accountState === AccountState.PaidSubscribed) {
			label.value = "Update Card";
			paymentType.value = "Update";
			totalAmount.value = 50;
			description.value = "Update Card";
		} else if (
			settingStore.$state.accountState === AccountState.CardInvalid
		) {
			label.value = "Pay Due + ";
			paymentType.value = "Update";
			totalAmount.value = 50;
			description.value = "Update Card - previously invalid";
		} else {
			label.value = "Upgrade for";
			paymentType.value = "Upgrade";
			totalAmount.value = 999;
			description.value = "Business Plan (1000 contacts )";
		}

		selectedOption.value = 0;
		isMonthlyBillingType.value = true;
	} else {
		//toast info
		toast("Unable to fetch Account state. Reload the page!", {
			theme: "auto",
			type: "error",
			hideProgressBar: false,
			transition: "slide",
		});
		return;
	}

	//Populate Discount Params
	const promoCode = store.$state?.authInfo?.account?.PromoCode ?? "";
	if (promoCode === "") {
		monthlyContactDiscount.value = 0;
		yearlyContactDiscount.value = 25;
		yearlyDefaultDiscount.value = true;
	} else if (
		settingStore.$state.promotionDurationApplicable === PROMO_CODE_BOTH
	) {
		monthlyContactDiscount.value =
			settingStore.$state.contactDiscountPercentage;
		yearlyContactDiscount.value =
			settingStore.$state.contactDiscountPercentage;
		yearlyDefaultDiscount.value = false;
	} else if (
		settingStore.$state.promotionDurationApplicable ===
		PROMO_CODE_ONLY_MONTHLY
	) {
		monthlyContactDiscount.value =
			settingStore.$state.contactDiscountPercentage;
		yearlyContactDiscount.value = 25;
		yearlyDefaultDiscount.value = true;
	} else if (
		settingStore.$state.promotionDurationApplicable === PROMO_CODE_ONLY_YEARLY
	) {
		monthlyContactDiscount.value = 0;
		yearlyContactDiscount.value =
			settingStore.$state.contactDiscountPercentage;
		yearlyDefaultDiscount.value = false;
	}

	// If card is invalid ask to update
	if (settingStore.$state.accountState === AccountState.CardInvalid) {
		initStripePayment();
		return;
	}

	isComponentModalActive.value = true;
	await settingStore.upgradeClicked();
};

const initStripePayment = () => {
	const email = store.$state?.authInfo?.account?.Email ?? "";
	paymentHandler.open({
		name: "SendX Inc.",
		description: description.value,
		zipCode: true,
		amount: totalAmount.value,
		panelLabel: label.value,
		email: email,
		allowRememberMe: false,
	});
};

window.addEventListener("popstate", function () {
	paymentHandler.close();
});

const resetData = async () => {
	store.globalSpinnerStart();
	await Promise.all([
		settingStore.getBillingState(),
		store.fetchAllMemberTeams()
	]);
	store.globalSpinnerStop();
};

const timezones = computed(() => {
	const tzs = [];
	let timezoneEntities = timeZoneGroups.value;
	for (let i = 0; i < timezoneEntities.length; i++) {
		for (let j = 0; j < timezoneEntities[i].options.length; j++) {
			tzs.push(timezoneEntities[i].options[j]);
		}
	}

	return tzs;
});

const timeZoneGroups = computed(() => {
	return getTimeZoneGroups(false).map((group) => {
		const options = group.zones.reduce((options, zone) => {
			const info = getTimeZoneInfo(zone, now);

			if (!info) {
				return options;
			}

			let country = { code: "", name: "" };
			if (info.countries.length > 0) country = info.countries[0];

			options.push({
				label: info.name,
				value: info.zone,
				country,
				abbreviation: info.abbreviation,
				searchIndex: getSearchIndex(info, now),
				utc: formatUtcOffset(now, info.zone),
			});

			return options;
		}, []);

		return {
			label: group.name,
			options,
		};
	});
});

const getSearchIndex = (info, timestamp) => {
	const parts = [
		toLower(info.name),
		toLower(info.abbreviation),
		toLower(formatUtcOffset(timestamp, info.zone)),
	];

	for (const country of info.countries) {
		parts.push(toLower(country.name));
		parts.push(toLower(country.code));
	}

	return parts.join("|");
};

const getCurrentTimezone = computed(() => {
	const offsetMinutes = moment().utcOffset();
	const offsetHours = offsetMinutes / 60;
	const sign = offsetMinutes >= 0 ? "+" : "-";
	const formattedOffset = `UTC${sign}${
		Math.abs(offsetHours) % 1 === 0
			? Math.abs(offsetHours)
			: Math.abs(offsetHours).toFixed(1)
	}`;

	for (let i = 0; i < timezones.value.length; i++) {
		const opt = timezones.value[i];
		if (opt.utc === formattedOffset) {
			return opt.label;
		}
	}

	return "Atlantic/Reykjavik";
});

const handleDefaultTimezoneUpdate = async () => {
	try {
		await store.updateDefaultTimezone({
			tid: store.$state.currentTeam.id,
			timezone: getCurrentTimezone.value,
		});
	} catch (error) {
		console.log(
			"Error occurred while updating your default timezone : ",
			error
		);
	}
};

onMounted(async () => {
	cookies.value = document.cookie;
	resetData();

	if (!window.Beamer) {
		console.log("Beamer loading...");
	}

	if (store.$state.currentTeam?.defaultTimeZone === "Atlantic/Reykjavik") {
		await handleDefaultTimezoneUpdate();
	}
});
</script>

<style scoped>
.active-tab:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.active-tab:focus {
	background-color: rgba(0, 0, 0, 0.2);
}

/*.nuxt-link-active.active-tab {*/
/*  background-color: rgba(0, 0, 0, 0.2);*/
/*}*/
</style>
