<template>
  <div class="bg-white w-44 shadow-lg rounded-md border border-gray-50 p-1">
    <div class="flex flex-col text-gray-900">
      <NuxtLink :to="$config.public.routes.Campaign.Newsletter.Home" class="px-2 py-2 text-sm leading-5 hover:text-gray-600 hover:bg-gray-100 hover:rounded-md flex items-center gap-2">
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.50635 1.99829C2.23291 2.01652 2.08708 2.16235 2.06885 2.43579V3.03735L6.79932 6.92017C7.09098 7.13892 7.40999 7.24829 7.75635 7.24829C8.12093 7.24829 8.44906 7.13892 8.74072 6.92017L13.4438 3.03735V2.43579C13.4256 2.16235 13.2798 2.01652 13.0063 1.99829H2.50635ZM2.06885 4.73267V9.43579V4.73267V9.43579C2.08708 9.70923 2.23291 9.85506 2.50635 9.87329H13.0063C13.2798 9.85506 13.4256 9.70923 13.4438 9.43579V4.73267L9.56104 7.93188C9.01416 8.35116 8.4126 8.56079 7.75635 8.56079C7.1001 8.56079 6.49854 8.35116 5.95166 7.93188L2.06885 4.73267ZM0.756348 2.43579C0.774577 1.9436 0.947754 1.53345 1.27588 1.20532C1.604 0.877197 2.01416 0.70402 2.50635 0.685791H13.0063C13.4985 0.70402 13.9087 0.877197 14.2368 1.20532C14.5649 1.53345 14.7381 1.9436 14.7563 2.43579V9.43579C14.7381 9.92798 14.5649 10.3381 14.2368 10.6663C13.9087 10.9944 13.4985 11.1676 13.0063 11.1858H2.50635C2.01416 11.1676 1.604 10.9944 1.27588 10.6663C0.947754 10.3381 0.774577 9.92798 0.756348 9.43579V2.43579Z" fill="#6B7280"/>
        </svg>
        Newsletters
      </NuxtLink>
      <NuxtLink :to="$config.public.routes.Campaign.Drip.Home"
        class="px-2 py-2 text-sm leading-5 hover:text-gray-600 hover:bg-gray-100 hover:rounded-md flex items-center gap-2">
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.06885 2.24829V6.18579H1.75635V2.24829C1.77458 1.88371 1.90218 1.57381 2.13916 1.3186C2.39437 1.08162 2.70426 0.95402 3.06885 0.935791H11.8188C12.1834 0.95402 12.4933 1.08162 12.7485 1.3186C12.9855 1.57381 13.1131 1.88371 13.1313 2.24829V3.56079H11.8188V2.24829H3.06885H11.8188H3.06885ZM1.31885 8.37329V8.5647L5.25635 11.4631L9.19385 8.5647V8.37329H1.31885H9.19385H1.31885ZM1.31885 10.2053V13.6233H9.19385V10.2053L6.04932 12.5295C5.81234 12.6936 5.54801 12.7847 5.25635 12.803C4.96468 12.7847 4.70036 12.6936 4.46338 12.5295L1.31885 10.2053L4.46338 12.5295L1.31885 10.2053ZM0.00634766 8.37329C0.0245768 8.00871 0.152181 7.69881 0.38916 7.4436C0.644368 7.20662 0.954264 7.07902 1.31885 7.06079H9.19385C9.55843 7.07902 9.86833 7.20662 10.1235 7.4436C10.3605 7.69881 10.4881 8.00871 10.5063 8.37329V13.6233C10.4881 13.9879 10.3605 14.2978 10.1235 14.553C9.86833 14.79 9.55843 14.9176 9.19385 14.9358H1.31885C0.954264 14.9176 0.644368 14.79 0.38916 14.553C0.152181 14.2978 0.0245768 13.9879 0.00634766 13.6233V8.37329V13.6233V8.37329ZM7.44385 6.18579H6.13135V5.74829C6.14958 5.38371 6.27718 5.07381 6.51416 4.8186C6.76937 4.58162 7.07926 4.45402 7.44385 4.43579H16.1938C16.5584 4.45402 16.8683 4.58162 17.1235 4.8186C17.3605 5.07381 17.4881 5.38371 17.5063 5.74829V10.9983C17.4881 11.3629 17.3605 11.6728 17.1235 11.928C16.8683 12.165 16.5584 12.2926 16.1938 12.3108H11.3813V10.9983H16.1938V5.74829H7.44385V6.18579V5.74829V6.18579ZM14.0063 6.62329H14.8813C15.1548 6.64152 15.3006 6.78735 15.3188 7.06079V7.93579C15.3006 8.20923 15.1548 8.35506 14.8813 8.37329H14.0063C13.7329 8.35506 13.5871 8.20923 13.5688 7.93579V7.06079C13.5871 6.78735 13.7329 6.64152 14.0063 6.62329Z" fill="#111827"/>
        </svg>
        Drips
      </NuxtLink>
      <NuxtLink :to="$config.public.routes.Campaign.ABTest.Home"
        class="px-2 py-2 text-sm leading-5 hover:text-gray-600 hover:bg-gray-100 hover:rounded-md flex items-center gap-2">
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.54541 1.34595L6.95166 7.03345C7.07926 7.41626 6.96077 7.69881 6.59619 7.8811C6.21338 8.00871 5.93083 7.89022 5.74854 7.52563L5.44775 6.84204H2.43994L2.13916 7.52563C1.95687 7.89022 1.67432 8.00871 1.2915 7.8811C0.926921 7.69881 0.808431 7.41626 0.936035 7.03345L3.34229 1.34595C3.46989 1.09074 3.67041 0.95402 3.94385 0.935791C4.21729 0.95402 4.41781 1.09074 4.54541 1.34595ZM4.90088 5.52954L3.94385 3.28735L2.98682 5.52954H4.90088H2.98682H4.90088ZM7.88135 1.59204C7.91781 1.191 8.13656 0.972249 8.5376 0.935791H10.6157C11.1991 0.95402 11.6912 1.15454 12.0923 1.53735C12.4751 1.9384 12.6756 2.43058 12.6938 3.01392C12.6938 3.46965 12.5662 3.87069 12.311 4.21704C12.8397 4.61808 13.1131 5.16496 13.1313 5.85767C13.1131 6.441 12.9126 6.93319 12.5298 7.33423C12.1287 7.71704 11.6366 7.91756 11.0532 7.93579H8.5376C8.13656 7.89933 7.91781 7.68058 7.88135 7.27954V4.43579V1.59204V4.43579V1.59204ZM10.6157 3.77954C10.8345 3.77954 11.0168 3.70662 11.1626 3.56079C11.3084 3.41496 11.3813 3.23267 11.3813 3.01392C11.3813 2.79517 11.3084 2.61287 11.1626 2.46704C11.0168 2.32121 10.8345 2.24829 10.6157 2.24829H9.19385V3.77954H10.6157H9.19385H10.6157ZM9.19385 5.09204V6.62329H11.0532C11.272 6.62329 11.4543 6.55037 11.6001 6.40454C11.7459 6.25871 11.8188 6.07642 11.8188 5.85767C11.8188 5.63892 11.7459 5.45662 11.6001 5.31079C11.4543 5.16496 11.272 5.09204 11.0532 5.09204H9.19385H10.6157H9.19385ZM16.4399 9.49438L10.9712 14.7444C10.6613 14.9996 10.3514 14.9996 10.0415 14.7444L7.19775 11.9006C6.94255 11.5907 6.94255 11.2808 7.19775 10.9709C7.50765 10.7157 7.81755 10.7157 8.12744 10.9709L10.5063 13.3499L15.5103 8.5647C15.8384 8.30949 16.1483 8.30949 16.4399 8.5647C16.6951 8.89282 16.6951 9.20272 16.4399 9.49438Z" fill="#6B7280"/>
        </svg>
        A/B Split Test
      </NuxtLink>
      <NuxtLink :to="$config.public.routes.Campaign.AutoTrigger.Home"
        class="px-2 py-2 text-sm leading-5 hover:text-gray-600 hover:bg-gray-100 hover:rounded-md flex items-center gap-2">
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.17041 1.15454C8.48031 0.881104 8.7902 0.862874 9.1001 1.09985L12.1626 3.9436C12.3084 4.08944 12.3813 4.2535 12.3813 4.43579C12.3813 4.61808 12.3084 4.78215 12.1626 4.92798L9.1001 7.77173C8.7902 8.00871 8.48031 7.99048 8.17041 7.71704C7.93343 7.40715 7.95166 7.09725 8.2251 6.78735L10.0571 5.09204H3.4126C2.84749 5.11027 2.38265 5.30168 2.01807 5.66626C1.65348 6.03084 1.46208 6.49569 1.44385 7.06079V8.15454C1.40739 8.55558 1.18864 8.77433 0.787598 8.81079C0.386556 8.77433 0.167806 8.55558 0.131348 8.15454V7.06079C0.149577 6.1311 0.468587 5.35636 1.08838 4.73657C1.70817 4.11678 2.48291 3.79777 3.4126 3.77954H10.0571L8.2251 2.08423C7.95166 1.77433 7.93343 1.46444 8.17041 1.15454ZM5.54541 8.15454C5.85531 7.8811 6.1652 7.86287 6.4751 8.09985L9.5376 10.9436C9.68343 11.0894 9.75635 11.2535 9.75635 11.4358C9.75635 11.6181 9.68343 11.7821 9.5376 11.928L6.4751 14.7717C6.1652 15.0087 5.85531 14.9905 5.54541 14.717C5.30843 14.4071 5.32666 14.0972 5.6001 13.7874L7.43213 12.092H2.5376C2.2277 12.092 1.97249 12.2014 1.77197 12.4202C1.55322 12.6207 1.44385 12.8759 1.44385 13.1858V14.2795C1.40739 14.6806 1.18864 14.8993 0.787598 14.9358C0.386556 14.8993 0.167806 14.6806 0.131348 14.2795V13.1858C0.149577 12.5113 0.386556 11.9462 0.842285 11.4905C1.29801 11.0347 1.86312 10.7978 2.5376 10.7795H7.43213L5.6001 9.08423C5.32666 8.77433 5.30843 8.46444 5.54541 8.15454Z" fill="#6B7280"/>
        </svg>
        Auto-Trigger
      </NuxtLink>
      <NuxtLink :to="$config.public.routes.Campaign.RSS.Home"
        class="px-2 py-2 text-sm leading-5 hover:text-gray-600 hover:bg-gray-100 hover:rounded-md flex items-center gap-2">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.13135 1.46704C1.16781 1.066 1.38656 0.847249 1.7876 0.810791C3.93864 0.82902 5.88916 1.35767 7.63916 2.39673C9.38916 3.41756 10.7746 4.80298 11.7954 6.55298C12.8345 8.30298 13.3631 10.2535 13.3813 12.4045C13.3449 12.8056 13.1261 13.0243 12.7251 13.0608C12.3241 13.0243 12.1053 12.8056 12.0688 12.4045C12.0506 10.4905 11.5858 8.75871 10.6743 7.20923C9.76286 5.65975 8.53239 4.42928 6.98291 3.51782C5.43343 2.60636 3.70166 2.14152 1.7876 2.12329C1.38656 2.08683 1.16781 1.86808 1.13135 1.46704ZM2.88135 11.7483C3.15479 11.7301 3.30062 11.5842 3.31885 11.3108C3.30062 11.0374 3.15479 10.8915 2.88135 10.8733C2.60791 10.8915 2.46208 11.0374 2.44385 11.3108C2.46208 11.5842 2.60791 11.7301 2.88135 11.7483ZM2.88135 9.56079C3.5376 9.57902 4.0389 9.87069 4.38525 10.4358C4.71338 11.0191 4.71338 11.6025 4.38525 12.1858C4.0389 12.7509 3.5376 13.0426 2.88135 13.0608C2.2251 13.0426 1.7238 12.7509 1.37744 12.1858C1.04932 11.6025 1.04932 11.0191 1.37744 10.4358C1.7238 9.87069 2.2251 9.57902 2.88135 9.56079ZM1.7876 4.74829C3.20947 4.76652 4.49463 5.11287 5.64307 5.78735C6.7915 6.48006 7.71208 7.40063 8.40479 8.54907C9.07926 9.69751 9.42562 10.9827 9.44385 12.4045C9.40739 12.8056 9.18864 13.0243 8.7876 13.0608C8.38656 13.0243 8.16781 12.8056 8.13135 12.4045C8.11312 11.2196 7.82145 10.1532 7.25635 9.20532C6.69124 8.25741 5.93473 7.5009 4.98682 6.93579C4.0389 6.37069 2.97249 6.07902 1.7876 6.06079C1.38656 6.02433 1.16781 5.80558 1.13135 5.40454C1.16781 5.0035 1.38656 4.78475 1.7876 4.74829Z" fill="#6B7280"/>
        </svg>
        RSS
      </NuxtLink>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped></style>
