<template>
    <div>
        <LazySmallSidebar :open="open">
            <div class="bg-white h-full w-full">
                <div class="bg-white flex flex-row justify-between py-4 px-8">
                    <div class="text-lg font-semibold text-gray-700 pt-4">
                        Manage teams
                    </div>
                    <div class="cursor-pointer" @click.stop="closeTeamSidebar">
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 hover:text-gray-800 h-5 w-5"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>

                <div class="mt-6 flex flex-col relative mx-6">
                  <div class="overflow-x-auto border-b border-gray-200 sm:rounded-lg shadow">
                  <BaseNewTable
                    :show-select="false"
                    :columns="tableHeaders"
                    :rows="memTeams"
                  >
                    <template #name-data="{ row }">
                      <div>
                        {{ row?.team?.name }}
                      </div>
                    </template>
                    <template #role-data="{ row }">
                      <div>
                        {{ memberRoleData[row.role] }}
                      </div>
                    </template>
                    <template #actions="{ row }" >
                      <div>
                        <div @click.stop="editTeamClicked()" class="py-1.5 mx-1 px-2 hover:bg-gray-100 rounded-md">
                          Edit name
                        </div>
                        <div @click.stop="deleteTeamClicked(row.team)" class="py-1.5 mx-1 px-2 hover:bg-gray-100 rounded-md text-red-500">
                          Delete team
                        </div>
                      </div>
                    </template>
                  </BaseNewTable>
                </div>
              </div>

                <div class="py-4 px-8 space-y-8 divide-y divide-gray-200">
                    <div class="space-y-8">
                        <div class="flex flex-row items-stretch py-4 w-full">
                            <button v-show="!loadingNewTeam" @click.stop="createTeamClicked" type="submit"
                                class="items-center px-3 py-2 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-full">
                                Create team
                            </button>
                            <div v-show="loadingNewTeam" type="submit" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                <SpinnersThreeDotsWhite />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LazySmallSidebar>

        <SettingTeamCreateModalForm
          :active="isCreateModalActive"
          @cancelModalClicked="cancelModalClicked"
          @createModalClicked="(name) => createTeam(name)"
        />

        <SettingTeamEditModalForm
          :active="isEditModalActive"
          @cancelModalClicked="cancelModalClicked"
        />

        <SettingTeamDeleteModalForm
          :active="isDeleteModalActive"
          :entityName="entityName"
          :elementId="elementId"
          :elementName="elementName"
          :deleteLoader="teamLoader"
          @cancelModalClicked="cancelModalClicked"
          @deleteModalClicked="(value) => deleteTeam(value)" 
        />
    </div>
</template>

<script setup>
import { AppEntities } from "~/constants"
import moment from 'moment-timezone';
import { formatUtcOffset, getTimeZoneGroups, getTimeZoneInfo } from "~/data/timezone"
import { toLower } from 'lodash'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const props = defineProps({
    value: {
      type: String,
      default: 'Select',
    },
    open: {
      type: Boolean,
      default: false
    },
})

const store = useGlobalStore();
const emit = defineEmits(["closeTeamSidebar"])
const loadingNewTeam = ref(false)
let now = Date.now()
let timezoneOptions = [];
const isCreateModalActive = ref(false)
const isEditModalActive = ref(false)
const isDeleteModalActive = ref(false)
const entityName = ref("Team");
const elementId = ref(0);
const elementName = ref("");
const teamLoader = ref(false);
const memberRoleData = ['Owner','Admin','Contributor', 'Viewer'];

const tableHeaders = computed(() => {
  return [
    {
      label: "Team",
      key: "name",
    },
    {
      label: "Your Role",
      key: "role",
    }
  ];
});

const memTeams = computed(() => {
	const data = store.getMemberTeams;
	if (data) {
    for (var i=0;i<data?.length;i++){
      if (store.checkVisibility({ entity: AppEntities.Team }) && data[i]?.team?.id !== currentTeam?.id && data[i]?.team?.type !== 0){
        data[i].hideActions = false
      } else {
        data[i].hideActions = true
      }
    }
		return data;
	}
	return false;
});

const closeTeamSidebar = () => {
    emit("closeTeamSidebar")
}

const currentTeam = computed(() => {
	return store.currentTeam
})

const timezones = computed(() => {
  const tzs = [];
  let timezoneEntities = timeZoneGroups.value;
  for (let i = 0; i < timezoneEntities.length; i++) {
    for (let j=0; j< timezoneEntities[i].options.length; j++){
      tzs.push(timezoneEntities[i].options[j])
    }
  }

  timezoneOptions = tzs;
  return tzs;
});

const timeZoneGroups = computed(() => {
  return getTimeZoneGroups(false).map((group) => {
    const options = group.zones.reduce((options, zone) => {
      const info = getTimeZoneInfo(zone, now);

      if (!info) {
        return options;
      }

      let country = { code: '', name: '' }
      if (info.countries.length > 0) country = info.countries[0]

      options.push({
        label: info.name,
        value: info.zone,
        country,
        abbreviation: info.abbreviation,
        searchIndex: getSearchIndex(info, now),
        utc: formatUtcOffset(now, info.zone)
      });

      return options;
    }, []);

    return {
      label: group.name,
      options,
    }
  })
});

const getSearchIndex = (info, timestamp) => {
  const parts = [
    toLower(info.name),
    toLower(info.abbreviation),
    toLower(formatUtcOffset(timestamp, info.zone)),
  ];

  for (const country of info.countries) {
    parts.push(toLower(country.name));
    parts.push(toLower(country.code));
  }

  return parts.join('|');
};

const getCurrentTimezone = computed(() => {
  const offsetMinutes = moment().utcOffset();
  const offsetHours = offsetMinutes / 60;
  const sign = offsetMinutes >= 0 ? '+' : '-';
  const formattedOffset = `UTC${sign}${Math.abs(offsetHours) % 1 === 0 ? Math.abs(offsetHours) : Math.abs(offsetHours).toFixed(1)}`;

  for (let i = 0; i < timezones.value.length; i++) {
    const opt = timezones.value[i];
    if (opt.utc === formattedOffset) {
      return opt.label
    }
  }

  return "Atlantic/Reykjavik"
})

const editTeamClicked = () => {
  isEditModalActive.value = true
}

const deleteTeamClicked = (val) => {
  elementName.value = val.name;
  elementId.value = val.id;
  isDeleteModalActive.value = true
}

const createTeamClicked = () => {
  isCreateModalActive.value = true
}

const cancelModalClicked = () => {
  isCreateModalActive.value = false
  isEditModalActive.value = false
  isDeleteModalActive.value = false
}

const createTeam = async (teamName) => {
    if (teamName.trim().length === 0) {
        toast('Team name cannot be empty...', {
            "theme": "auto",
            "type": "warning",
            "hideProgressBar": false,
            "autoClose": 3000,
            "transition": "slide",
        })
    } else {
        try {
            loadingNewTeam.value = true
            const res = await store.createTeam({ name: teamName, timezone: getCurrentTimezone.value });
            toast('Team has been created successfully', {
                "theme": "auto",
                "type": "success",
                "hideProgressBar": false,
                "autoClose": 3000,
                "transition": "slide",
            })
            resetData()
            cancelModalClicked();
        } catch (err) {
            console.log("An error occurred while creating a team : ", err)
            toast('An error occurred while creating a team :(', {
                "theme": "auto",
                "type": "error",
                "hideProgressBar": false,
                "autoClose": 3000,
                "transition": "slide",
            })
        } finally {
            loadingNewTeam.value = false
        }
    }
}

const deleteTeam = async (id) => {
	try {
		teamLoader.value = true;
		const res = await store.deleteTeam({ sid: id });
		toast("Team deleted successfully", {
			theme: "auto",
			type: "success",
			hideProgressBar: false,
			autoClose: 3000,
			transition: "slide",
		});
    resetData()
		cancelModalClicked();
	} catch (err) {
		console.log("got error while deleting team : ", err);
		if (err.data?.status === "403") {
			toast("Deleting team from different account not allowed. ", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		} else {
			toast("Error occurred while deleting team :(", {
				theme: "auto",
				type: "error",
				hideProgressBar: false,
				autoClose: 3000,
				transition: "slide",
			});
		}
		cancelModalClicked();
	} finally {
		teamLoader.value = false;
	}
};

const resetData = async () => {
	store.globalSpinnerStart();
	await Promise.all([
		store.fetchAllMemberTeams()
	]);
	store.globalSpinnerStop();
};

</script>

<style scoped></style>
    